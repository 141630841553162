import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { getIcon } from '../../../Utils/WeatherUtils'
import { WindSpeedBar } from '../../../Utils/WeatherIcons'
import { Colors_Bar_Label, Colors_Rain, Colors_Humidity, GraphSettings } from '../../NivoTheme'

export class OPWForecastHourlyChartBar extends React.PureComponent {
  render() {
    const data = this.props
    const getData = () => {
      let resultArray = Object.keys(data).map(i => data[i])
      return resultArray.reverse()
    }
    // console.log(data)

    const WindSpeeds = ({ bars }) => {
      // console.log(bars)
      const size = 36;
      const WindSpeeds = bars.map(({
        height, key, width, x, y, data
      }) => {
        // console.log('OPWForecastHourlyChartBar', WindSpeedBar(data.data.WindSpeed))
        const imageSize = width > 15 ? size : 0;
        const imagePosX = x + width / 2 - size / 2;
        // const imagePosY = y + height / 2 - size / 2;
        // const decileImageKey = key.substring(0, key.indexOf("."));
        const xlinkHref = "svgs/" + WindSpeedBar(data.data.WindSpeed) + ".svg";
        // const xlinkHref = "svgs/wi-cloud-down.svg"
        return (
          <image
            key={key}
            alt=""
            height={imageSize}
            width={imageSize}
            x={imagePosX}
            href={xlinkHref}
            y={23}
          />
        );
      });
      return <g>{WindSpeeds}</g>;
    };


    const WeatherIcon = ({ bars }) => {
      // console.log(bars)
      const size = 36;
      const images = bars.map(({
        height, key, width, x, y, data
      }) => {
        // console.log('OPWForecastHourlyChartBar', getIcon(data.data.Icon))
        const imageSize = width > 15 ? size : 0;
        const imagePosX = x + width / 2 - size / 2;
        // const imagePosY = y + height / 2 - size / 2;
        // const decileImageKey = key.substring(0, key.indexOf("."));
        const xlinkHref = "svgs/" + getIcon(data.data.Icon) + ".svg";
        // const xlinkHref = "svgs/wi-cloud-down.svg"
        return (
          <image
            key={key}
            alt=""
            height={imageSize}
            width={imageSize}
            x={imagePosX}
            href={xlinkHref}
            y={0}
          />
        );
      });
      return <g>{images}</g>;
    };

    // const WeatherPop = ({ bars }) => {
    //   // console.log(bars)
    //   const size = 36;
    //   const images = bars.map(({
    //     height, key, width, x, y, data
    //   }) => {
    //     // console.log('OPWForecastHourlyChartBar', getIcon(data.data.Icon))
    //     const imageSize = 28;
    //     const imagePosX = x + width / 2 - size / 2;
    //     // const imagePosY = y + height / 2 - size / 2;
    //     // const decileImageKey = key.substring(0, key.indexOf("."));
    //     const xlinkHref = "svgs/wi-raindrop.svg";
    //     // const xlinkHref = "svgs/wi-cloud-down.svg"
    //     return (
    //       <g>
    //         <text
    //           key={key}
    //           fill="black"
    //           x={imagePosX}
    //           y={160}
    //           dominant-baseline="central"
    //           text-anchor="middle"
    //           font-size="12"
    //         // font-family="Verdana"
    //         >{data.data.Pop}</text>
    //         <image
    //           key={key}
    //           alt=""
    //           height={imageSize}
    //           width={imageSize}
    //           x={imagePosX}
    //           href={xlinkHref}
    //           y={180}
    //         />

    //       </g>
    //     );

    //   });
    //   return <g>{images}</g>;
    // };

    return (
      <>
        <div style={{ height: '25%' }}>
          <ResponsiveBar
            theme={GraphSettings.theme}
            labelTextColor={Colors_Bar_Label}
            isInteractive={false}
            borderRadius={6}
            labelSkipHeight={8}
            layers={['grid', 'axes', 'bars', WindSpeeds, WeatherIcon, 'markers', 'legends', 'annotations', 'points']}
            data={getData()[0]}
            keys={[
              "Humidity",
              // "Icon",
              // "Pop",
              // "HourofDay",
            ]}
            indexBy="id"
            margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
            // minValue={-10}
            maxValue={260}
            padding={0.05}
            groupMode="grouped"
            colors={Colors_Humidity}
            colorBy="id"
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'temp',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#3772A9',
                size: 4,
                padding: 2,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#3C7CB9',
                rotation: -50,
                lineWidth: 2,
                spacing: 5
              }
            ]}
            fill={[
              {
                match: {
                  id: 'Humidity'
                },
                id: 'lines'
              }
            ]}
            axisLeft={null}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        <div style={{ height: 60 }}>
          <ResponsiveBar
            theme={GraphSettings.theme}
            labelTextColor={Colors_Bar_Label}
            isInteractive={false}
            borderRadius={6}
            labelSkipHeight={8}
            // layers={['grid', 'axes', 'bars', WeatherPop, 'markers', 'legends', 'annotations', 'points']}
            data={getData()[0]}
            keys={[
              // "Humidity",
              // "Icon",
              "Rain",
              // "HourofDay",
            ]}
            indexBy="id"
            margin={{ top: 5, right: 10, bottom: 25, left: 10 }}
            // minValue={-10}
            maxValue={20}
            padding={0.05}
            groupMode="grouped"
            colors={Colors_Rain}
            colorBy="id"
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            // axisBottom={{
            //   orient: 'bottom',
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: 'temp',
            //   legendOffset: 36,
            //   legendPosition: 'middle'
            // }}
            axisLeft={null}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </>
    );
  }
}

export default OPWForecastHourlyChartBar

