import React, { useContext } from "react";
import { AppContext } from "../../context/Default";
import { useAuthState } from "react-firebase-hooks/auth";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarMonthIcon from '@material-ui/icons/CalendarToday';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import SignOut from "../SignOut";

import { ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link
} from "react-router-dom";

import {
  Grid,
  Box,
  CssBaseline,
  responsiveFontSizes,
  Hidden,
  Tooltip,
  IconButton
} from "@material-ui/core";

import { FetchHistoryRecords, FetchSpecificHistoryRecords, FetchFuelPricesNomos } from "../../services/FirestoreAPI";

import WuETOLI1 from "../Wunderground/WuEtoli1";
import WuWindv2 from "../Wunderground/WuWind";
import WuRainv2 from "../Wunderground/WuRain";
import WuSkyv2 from "../Wunderground/WuSky";
import WuMoon from "../Wunderground/WuMoon";
import NivoLegend from "../Nivo/NivoLegend";
import OPWForecastDailyv2 from "../OPW/OPWForecastDaily";
import OPWForecastHourly from "../OPW/OPWForecastHourly";

// import HistoryTempStats from "../HistoryFirstRow/HistoryTempStats";
import HistoryRainChartBar from "../OPW/HistoryRainChartBar"
import HistoryTempChartBar from "../OPW/HistoryTempChartBar"
import HistoryWindChartBar from "../OPW/HistoryWindChartBar"
import HistorySolarChartBar from "../OPW/HistorySolarChartBar"

import AllHistoryHeatIndexChartBar from "../OPW/AllHistoryHeatIndexChartBar ";
import AllHistoryFeelsLikeChartBar from "../OPW/AllHistoryFeelsLikeChartBar ";
import NomosFuelPrice from "../Paratiritio/Nomos";
import WodList from "../Wod";

import { THEME, ThemeUseStyles } from "../Theme"

export default function Dashboard() {
  const classes = ThemeUseStyles();
  const { Auth } = useContext(AppContext);
  const [currentUser] = useAuthState(Auth);

  // console.log("user", currentUser.email)
  const historyRecords = FetchHistoryRecords(30);
  const heatindexhigh = FetchSpecificHistoryRecords("HeatindexHigh", ">=", 43);
  const feelslikeunder = FetchSpecificHistoryRecords("WindChillLow", "<", -2);
  const fuelpricesNomos95 = FetchFuelPricesNomos("fuelprice_unleaded_95");
  const fuelpricesNomos100 = FetchFuelPricesNomos("fuelprice_unleaded_100");
  const fuelpricesNomosDiesel = FetchFuelPricesNomos("fuelprice_diesel");


  // console.log("use", feelslikeunder)
  return (
    <Router>
      <div className={classes.root}>
        <ThemeProvider theme={responsiveFontSizes(THEME)}>
          <CssBaseline />
          <AppBar
          // position="absolute"
          // className={clsx(classes.appBar, !open && classes.appBarShift)}
          >
            <Toolbar
              disableGutters
              sx={{
                minHeight: 64,
                left: 0,
                px: 2,
              }}
            >
              <Link to='/'>
                <Tooltip title="Dashboard">
                  <IconButton sx={{ ml: 1 }}>
                    <DashboardIcon />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to='/history'>
                <Tooltip title="Last 30 days">
                  <IconButton sx={{ ml: 1 }}>
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to='/peryear'>
                <Tooltip title="All time high low values">
                  <IconButton sx={{ ml: 1 }}>
                    <CalendarMonthIcon />
                  </IconButton>
                </Tooltip>
              </Link>


              {
                (() => {
                  if (currentUser.email === 'george.pagaremos@gmail.com' || currentUser.email === 'zzzremos@gmail.com') {
                    return (
              <Link to='/wodlink'>
                      <Tooltip title="WOD">
                        <IconButton sx={{ ml: 1 }}>
                          <FitnessCenterIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>

              );
              }
              })()
              }


              {
                (() => {
                  if (currentUser.email === 'george.pagaremos@gmail.com' || currentUser.email === 'zzzremos@gmail.com') {
                    return (

                      <Link to='/petrolprices'>
                        <Tooltip title="Petrol Prices">
                          <IconButton sx={{ ml: 1 }}>
                            <WhatshotIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      


                    );
                  }
                })()
              }






              {/* </IconButton> */}
              <Box sx={{ flexGrow: 1 }} />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {currentUser.displayName}
              </Typography>

              <Avatar
                alt={currentUser.displayName}
                src={currentUser.photoURL}
                className={classes.avatar}
              />

              <SignOut />

            </Toolbar>
          </AppBar>

          {/* <main className={classes.content}> */}
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container >
              {/* Routes */}
              <Routes>
                {/* Dashboard */}
                <Route path="/" element={
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item={true} xs={6} sm={2} >
                        <WuETOLI1 />
                      </Grid>
                      <Grid item={true} xs={6} sm={2} >
                        <WuWindv2 />
                      </Grid>
                      <Grid item={true} xs={6} sm={2} >
                        <WuRainv2 />
                      </Grid>
                      <Grid item={true} xs={6} sm={2} >
                        <WuSkyv2 />
                      </Grid>
                      <Hidden only={['xs']}>
                        <Grid item={true} xs={6} sm={2} >
                          <WuMoon />
                        </Grid>
                        <Grid item={true} xs={6} sm={2} >
                          <NivoLegend />
                        </Grid>
                      </Hidden>
                      <Grid item={true} xs={12} sm={12}>
                        <OPWForecastHourly />
                      </Grid>
                      <Grid item={true} xs={12} sm={12}>
                        <OPWForecastDailyv2 />
                      </Grid>
                    </Grid>
                  </Grid>
                } />
                {/* Orders */}
                <Route path="/history" element={
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item={true} xs={12} sm={12}>
                        <Box key={'history' + historyRecords.id} style={{ display: 'flex', width: '100%' }}>
                          <Box flexGrow={1}>
                            <Typography variant="h6" >
                              Rain
                            </Typography>
                            <HistoryRainChartBar data={historyRecords} />
                            <Typography variant="h6" >
                              Feelslike
                            </Typography>
                            <HistoryTempChartBar data={historyRecords} />
                            <Typography variant="h6" >
                              Wind/Gust
                            </Typography>
                            <HistoryWindChartBar data={historyRecords} />
                            <Typography variant="h6" >
                              SolarRadiation
                            </Typography>
                            <HistorySolarChartBar data={historyRecords} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                } />

{
                  (() => {
                    if (currentUser.email === 'george.pagaremos@gmail.com' || currentUser.email === 'zzzremos@gmail.com') {
                      return (

                <Route path="/wodlink" element={
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item={true} xs={12} sm={12}>
                        <Box key={'history' + historyRecords.id} style={{ display: 'flex', width: '100%' }}>
                          <Box flexGrow={1}>
                            <WodList />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                } />


                );
              }
            })()
          }


                <Route path="/peryear" element={
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item={true} xs={12} sm={12}>
                        <Box key={'history' + historyRecords.id} style={{ display: 'flex', width: '100%' }}>
                          <Box flexGrow={1}>
                            <Typography variant="h6" >
                              HeatIndex over 43
                            </Typography>
                            <AllHistoryHeatIndexChartBar data={heatindexhigh} />
                            <Typography variant="h6" >
                              Feelslike under 0
                            </Typography>
                            <AllHistoryFeelsLikeChartBar data={feelslikeunder} />
                            <Typography variant="h6" >
                              Rain over 10
                            </Typography>
                            <HistoryWindChartBar data={historyRecords} />
                            <Typography variant="h6" >
                              Solar over 800
                            </Typography>
                            <HistorySolarChartBar data={historyRecords} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                } />

                {
                  (() => {
                    if (currentUser.email === 'george.pagaremos@gmail.com' || currentUser.email === 'zzzremos@gmail.com') {
                      return (

                        <Route path="/petrolprices" element={
                          <Grid
                            container
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            justify="center"
                          // justifyContent="space-between"
                          >
                            <Box key={'history' + historyRecords.id} style={{ display: 'flex', width: '100%' }}>
                              <Box flexGrow={1}>
                                <Typography variant="h6" >
                                  Αμολυβδη 95
                                </Typography>
                                <NomosFuelPrice data={fuelpricesNomos95} />
                                <Typography variant="h6" >
                                  Αμολυβδη 100
                                </Typography>
                                <NomosFuelPrice data={fuelpricesNomos100} />
                                <Typography variant="h6" >
                                  Diesel
                                </Typography>
                                <NomosFuelPrice data={fuelpricesNomosDiesel} />
                              </Box>
                            </Box>
                          </Grid> 
                        } />


                      );
                    }
                  })()
                }


                {/* Customers */}

                {/* Redirect none matches routes */}
                <Route render={() => <Navigate to="/dashboard" />} />
              </Routes>
            </Grid>
            {/* <Box pt={4}>
              <Footer />
            </Box> */}
          </Container>
          {/* </main> */}
        </ThemeProvider>
      </div>
    </Router>
  );
}
