import React from "react";
import { Color_Text_Orange,  } from '../../Theme'
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    Grid,
    Paper,
} from "@material-ui/core";

import { FetchWuETOLI1, FetchOPWforecastDaily } from "../../../services/FirestoreAPI";

function WuETOLI1() {
    const classes = ThemeUseStyles();
    const OPWforecast = FetchWuETOLI1(1);
    const OPWforecastDaily = FetchOPWforecastDaily();
 
    return (
        <div className={classes.boxes}>
                    {/* {isLoading ? (
                    <CircularProgress />
                ) : ( */}
                        <>
                            <Paper className={classes.bgplumplate} elevation={3} >
                                <Typography variant="h6" gutterBottom>
                                    Temp
                                </Typography>

                                {OPWforecast.map(OPWforecast => OPWforecast &&
                                    <div key={"WuEtoli1" + OPWforecast.id}>
                                        <Grid container>
                                            <Grid container direction="column" item xs={4}>
                                                {OPWforecastDaily &&
                                                    <Grid
                                                        item
                                                        xs
                                                        className={classes.outerColumn}
                                                        style={{ display: "flex", justifyContent: "flex-start" }}
                                                    >
                                                        <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                            <i className="wi wi-cloud-up"></i>
                                                        &nbsp;
                                                        {OPWforecastDaily.TempMax}
                                                            <br></br>
                                                            <Typography variant="h5" color="textSecondary" gutterBottom>tempmax</Typography>
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumn}
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >

                                                    {/* <Typography>Center L.</Typography> */}
                                                </Grid>

                                            </Grid>
                                            <Grid container direction="column" item xs={4} align="center">
                                                <Grid
                                                    item
                                                    container
                                                    className={classes.centerColumn}
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    {/* <Typography>Top Center</Typography> */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    className={classes.centerColumn}
                                                    direction="column"
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="h3" color="textPrimary" >{OPWforecast.Temp}</Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classes.centerColumn}
                                                    container
                                                    direction="row"
                                                    alignItems="flex-end"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }}  gutterBottom>
                                                        {OPWforecast.HeatIndex}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom >feelslike</Typography>
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column" item xs={4}>
                                                {OPWforecastDaily &&
                                                    <Grid
                                                        item
                                                        className={classes.outerColumn}
                                                        container
                                                        direction="column"
                                                        alignItems="flex-end"
                                                        justifyContent="flex-start"
                                                    >
                                                        <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                            <i className="wi wi-cloud-down"></i>
                                                        &nbsp;
                                                        {OPWforecastDaily.TempMin}
                                                            <br></br>
                                                            <Typography variant="h5" color="textSecondary" gutterBottom >tempmin</Typography>
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    className={classes.outerColumn}
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="flex-end"
                                                >
                                                    {/* <Typography>Center R.</Typography> */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classes.outerColumn}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-end"
                                                    justifyContent="flex-end"
                                                >
                                                    {/* <Typography>Bottom R.</Typography> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </Paper>
                        </>
                    {/* )} */}
        </div>
    )
}
export default WuETOLI1;

