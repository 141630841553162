import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Colors_Bar_Label, Colors_TempMinMax, GraphSettings } from '../../Nivo/NivoTheme'

export class HistoryTempChartBar extends React.PureComponent {
  render() {
    const data = this.props
    const getData = () => {
      let resultArray = Object.keys(data).map(i => data[i])
      return resultArray.reverse()
    }
    // console.log(data)

    return (
      <>
        <div style={{ display: 'flex', height: '20%' }}>
          <ResponsiveBar
            theme={GraphSettings.theme}
            labelTextColor={Colors_Bar_Label}
            isInteractive={false}
            borderRadius={6}
            // labelSkipHeight={8}
            layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', 'points']}
            data={getData()[0]}
            keys={[
              // "TempMax",
              "WindChillHigh",
              // "TempMin",
              "WindChillLow"
            ]}
            indexBy="id"
            margin={{ top: 20, bottom: 20, left: 0, right: 0 }}
            padding={0.1}
            groupMode="grouped"
            colors={Colors_TempMinMax}
            // colors={{ scheme: 'purple_orange' }}
            colorBy="id"
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 2,
              tickPadding: 4,
              tickRotation: 8,
              // legend: 'id',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={null}
            // animate={false}
            // motionStiffness={90}
            // motionDamping={15}
          />
        </div>
        
      </>
    );
  }
}

export default HistoryTempChartBar

