import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Color_Text_Orange } from '../../Utils/Theme'
import WeatherIcon from '../../Utils/icons/WeatherIcon';
import { MoonIcons } from '../../Utils/WeatherIcons';
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    Grid,
    Paper,
} from "@material-ui/core";

import { FetchWuMoon } from "../../../services/FirestoreAPI";


function timeconverter(unixtime) {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(unixtime * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime
}


// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     bgplumplate: {
//         width: '100%',
//         backgroundColor: theme.palette.secondary.dark
//         // backgroundColor: '#f5f5f5',
//     },
//     centerColumn: {
//         padding: 5,
//         height: 53,
//     },
//     outerColumn: {
//         padding: 5,
//         height: 80,
//     }

// }))

// let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);



function WuMoon() {
    const classes = ThemeUseStyles();
    const OPWforecastRain = FetchWuMoon();

    return (
        <div className={classes.boxes}>
           
                {/* {isLoading ? (
                    <CircularProgress />
                ) : ( */}
                        <>
                            <Paper className={classes.bgplumplate} >
                                <Typography variant="h6" gutterBottom>
                                     Moon
                                </Typography>
                                {OPWforecastRain &&
                                    <div key={"WuMoon" + OPWforecastRain.id}  >
                                        <Grid container >
                                            <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumnlarge}
                                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                    
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }}gutterBottom>
                                                        <i class="wi wi-moon-full"></i>
                                                        &nbsp;
                                                        {OPWforecastRain.MoonNextFull}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom>next full</Typography>
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumnlarge}
                                                    direction="column"
                                                    align="left"
                                                    style={{ display: "flex", justifyContent: "flex-end" }}
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        {/* {OPWforecastRain.MoonPhase} */}
                                                        <i class="wi wi-moonrise"></i>  
                                                        &nbsp;
                                                        {timeconverter(OPWforecastRain.MoonriseTimeUtc)}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom>moonrise</Typography>
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                    <Grid container direction="column" item xs={4} align="center">
                                        
                                        <Grid
                                            item
                                            container
                                            className={classes.centerColumn}
                                            display="flex"
                                            justify="center"
                                        >
                                            {/* <Typography>Top Center</Typography> */}
                                        </Grid>
                                        
                                        <Grid
                                            item
                                            container
                                            className={classes.centerColumn}
                                            direction="column"
                                            display="flex"
                                            justify="center"
                                        >
                                        <Typography variant="h3" color="textPrimary" >
                                            <WeatherIcon name={MoonIcons(OPWforecastRain.MoonPhaseDay)} />
                                        </Typography>
                                        </Grid>
                                        
                                        <Grid
                                            item
                                            className={classes.centerColumn}
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                            justify="center"
                                        >
                                            <Typography variant="h5" style={{ color : Color_Text_Orange }}gutterBottom>
                                            {OPWforecastRain.Description}
                                                <br></br>
                                                {/* <Typography variant="h8" color="textSecondary" gutterBottom >monthly</Typography> */}
                                            </Typography>
                                        </Grid>
                                        
                                    </Grid>

                                            <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    className={classes.outerColumnlarge}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-end"
                                                    justify="flex-start"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        <i class="wi wi-moon-new"></i>
                                                        &nbsp;
                                                        {OPWforecastRain.MoonNextNew} 
                                                        <Typography display="block" variant="h5" align="right" color="textSecondary" >next new </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classes.outerColumnlarge}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-end"
                                                    justify="flex-end"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        <i class="wi wi-moonset"></i>
                                                        &nbsp;
                                                        {timeconverter(OPWforecastRain.MoonsetTimeUtc)}
                                                        <br></br>
                                                        <Typography display="block" variant="h5" align="right" color="textSecondary" gutterBottom>moonset</Typography>

                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>}
                            </Paper>
                        </>
                    {/* )} */}
      
        </div>
    )
}
export default WuMoon;