import React, { useContext } from "react";
import { AppContext } from "../../context/Default";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Footer from "../Footer";


const useStyles = makeStyles((theme) => ({
  palette: {
    type: "dark",
  },
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random?agriculture)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#424242",
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  svg: {
    width: "100%",
    height: "100%"
  },
  title: {
    margin: theme.spacing(1, 0, 3)
  },
  card: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  button: {
    margin: theme.spacing(1),
    height: theme.spacing(7),
    fontSize: "14px",
    textTransform: "capitalize"
  }
}));

export default function SignInSide() {
  const classes = useStyles();

  const { firebase, cloudRef, Auth } = useContext(AppContext);

  const addUserToFirestore = async (result) => {
    if (!result.additionalUserInfo.isNewUser) return;

    const { displayName, email, photoURL, uid } = result.user;

    await cloudRef("users")
      .add({
        displayName,
        photoURL,
        id: uid,
        email
      })
      .then(() => {
        console.log("User added to database");
      })
      .catch((error) => {
        console.log("error adding user to the database", error);
      });
  };

  const handleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    Auth.signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const token = result.credential.accessToken
        // The signed-in user info.
        // console.log(result)
        addUserToFirestore(result);
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code
        //const errorMessage = error.message
        // The email of the user's account used.
        //const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        //const credential = error.credential
        // ...
        console.log(error);
      });
  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SvgIcon viewBox="0 0 24 24" className={classes.svg}>
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
            <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
            <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
            <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
            <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
          </g>
            </SvgIcon>
          </Avatar>
          <Typography component="h1" variant="h5" className={classes.title}>
            Sign in!
          </Typography>
          <div className={classes.card}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              className={classes.button}
              fullWidth
              startIcon={<LockOutlinedIcon />}
            >
              Sign in with your Google Account
            </Button>
            <Box mt={5}>
              <Footer />
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
