import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

export const App = firebase.initializeApp({
    apiKey: "AIzaSyBsZ7nFuicEWKvR-7amehykUbNuJFmvU8w",
    authDomain: "zzzremos01.firebaseapp.com",
    databaseURL: "https://zzzremos01.firebaseio.com",
    projectId: "zzzremos01",
    storageBucket: "zzzremos01.appspot.com",
    messagingSenderId: "393571243052",
    appId: "1:393571243052:web:c2911ea37f4cc997cfad89",
    measurementId: "G-X3FPQYCBNT"
});

const firebaseConfig = {
    apiKey: "AIzaSyBsZ7nFuicEWKvR-7amehykUbNuJFmvU8w",
    authDomain: "zzzremos01.firebaseapp.com",
    databaseURL: "https://zzzremos01.firebaseio.com",
    projectId: "zzzremos01",
    storageBucket: "zzzremos01.appspot.com",
    messagingSenderId: "393571243052",
    appId: "1:393571243052:web:c2911ea37f4cc997cfad89",
    measurementId: "G-X3FPQYCBNT"
  };


const app1 = initializeApp(firebaseConfig);
// export const auth1 = getAuth(app1);
export const db = getFirestore(app1);

export const Auth = firebase.auth();
export const Firestore = firebase.firestore();
export const Storage = firebase.storage();



export default firebase;