import moment from 'moment';
import { icons, wuicons, WindDirection } from './WeatherIcons';
import { langText } from './WeatherLang';

export const getIcon = (icon) => {
  if (!icon) {
    return 'na';
  }
  const icoClass = icons[icon];
  if (icoClass) {
    return icoClass;
  }
  return 'na';
}

export const getWindIcon = (icon) => {
  if (!icon) {
    return 'na';
  }
  const icoClass = WindDirection[icon];
  if (icoClass) {
    return icoClass;
  }
  return 'na';
}

export const getIconwu = (icon) => {
  if (!icon) {
    return 'na';
  }
  const icoClass = wuicons[icon];
  if (icoClass) {
    return icoClass;
  }
  return 'na';
}

export const getUnits = (unit) => {
  if (unit === 'metric') {
    return {
      temp: '°C',
      speed: 'km/h',
    };
  } else if (unit === 'imperial') {
    return {
      temp: '°F',
      speed: 'mph',
    };
  }
  return { temp: '', speed: '' };
}

export const formatDate = (dte, lang)  => {
  if (dte && moment(dte).isValid()) {
    moment.locale(lang);
    return moment.unix(dte).format('ddd D MMMM');
  }
  return '';
}

export const getLangs = (lang) => {
  return langText[lang] === undefined ? langText.en : langText[lang];
  }

  export const getNextDays = (tomorrow) => {
    var fourDates = [];
    for(var i=0; i<5; i++) {
        tomorrow.setDate(tomorrow.getDate() + 1);
        var month = tomorrow.getMonth() + 1;
        var day = tomorrow.getDate();
        var year = tomorrow.getFullYear();

        if (month < 10) { month = "0" + month } 
        if (day < 10) { day = "0" + day }
        fourDates.push(year + "-" + month + "-" + day);
    }  
    // console.log(fourDates)
    return fourDates;
}

// const today = new Date()
// const tomorrow = new Date(today)
// tomorrow.setDate(tomorrow.getDate() + 1)

