import React, { useContext } from "react";
import { AppContext } from "./context/Default";
import { useAuthState } from "react-firebase-hooks/auth";

import SignIn from "./components/SignIn";
import Dashboard from "./components/Dashboard";
import Loading from "./components/Loading";
// import "./App.css";

function App() {
  const { Auth } = useContext(AppContext);
  const [user, loading, error] = useAuthState(Auth);
  if (error) return;
  if (loading) return <Loading />;

  return <div>{user ? <Dashboard /> : <SignIn />}</div>;
}

export default App;