import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import {Colors, GraphSettings} from '../../NivoTheme'

function lower(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] === 'string') {
            obj[prop] = obj[prop].toLowerCase();
        }
        if (typeof obj[prop] === 'object') {
            lower(obj[prop]);
        }
    }
    return obj;
}

const toLowerCase = function (data) {
    return JSON.parse(JSON.stringify(data)
        .replace(/"([^"]+)":/g, ($0, key) => {
            return '"' + key.toString().toLowerCase() + '":'
        }))
}

export class OPWForecastHourlyChartLine extends React.PureComponent {
    render() {

        const data = this.props
        const jsontolowercase = toLowerCase(lower(data))
        const result = jsontolowercase.data

        return (
            <div style={{ height: '37%'}}>
                <ResponsiveLine
                    theme={GraphSettings.theme}
                    layers={['grid', 'axes', 'lines', 'markers', 'legends', 'annotations', 'points']}
                    data={result}
                    margin={{ top: 17, right: 100, bottom: 20, left: 100 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    yFormat=" >-.0f"
                    axisTop={null}
                    axisRight={null}
                    curve="catmullRom"
                    colors={Colors}
                    // markers={markers}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 2,
                        tickRotation: 0,
                        // legend: 'temp',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={null}
                    enableGridX={false}
                    enableGridY={false}
                    lineWidth={3}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={1}
                    pointSize={4}
                    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                    enablePointLabel={true}
                    pointLabelYOffset={-8}
                    // enableArea={true}
                    // areaOpacity={0.25}
                    // areaBlendMode="lighten"
                    useMesh={true}
                    legends={[]}
                />
            </div>
        );
    }
}

export default OPWForecastHourlyChartLine



