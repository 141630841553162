import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';


const columns = [
    {
      field: 'Price',
      headerName: 'Τιμη',
      width: 60,
      editable: true,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      // sortingOrder: 'asc',
    },
    {
      field: 'Time',
      headerName: 'Time',
      width: 140,
      editable: true,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      // sortingOrder: 'asc',
    },
    {
      field: 'Name',
      headerName: 'Ονομα',
      width: 240,
      editable: true,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      cellClassName: (params) =>
      clsx('super-app', {
        kotsafti: params.value === "ΚΟΤΣΑΦΤΗ ΠΑΝΑΓΙΩΤΑ",
        // positive: params.value > 0,
      }),
    },
    {
      field: 'Dimos',
      headerName: 'Δημος',
    //   type: 'number',
      width: 200,
      editable: true,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      cellClassName: (params) =>
      clsx('super-app', {
        dimos: params.value === "ΔΗΜΟΣ ΙΕΡΑΣ ΠΟΛΗΣ ΜΕΣΟΛΟΓΓΙΟΥ" || params.value === "ΔΗΜΟΣ ΑΙΤΩΛΙΚΟΥ" || params.value === "ΔΗΜΟΣ ΟΙΝΙΑΔΩΝ" ,
        // positive: params.value > 0,
      }),
    },
    {
      field: 'Product',
      headerName: 'Τυπος',
    //   type: 'number',
      width: 200,
      editable: true,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
    },
  ];
  

  const useStyles = makeStyles({
    root: {
      '& .super-app-theme--header': {
        // backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#336699',
        fontWeight: '600',
      },
      
      '& .super-app.kotsafti': {
        color: '#CCA43B',
        fontWeight: '600',
      },
      '& .super-app.dimos': {
        color: '#6F1D1B',
        fontWeight: '600',
      },
    },
  });



  function NomosFuelPrice (data) {
    // console.log("NomosFuelPriceDataGrid", data.data)
    const classes = useStyles();
    return (
      <div style={{ width: '78.3%' }} className={classes.root}>
    
        <DataGrid
          rowHeight={20}
          headerHeight={23}
          rows={data.data}
          columns={columns}
          pageSize={20}
          // checkboxSelection
          disableSelectionOnClick
          autoHeight
          // hideFooter
          disableColumnMenu
          // pagination

        />
  
      </div>
    );
  }

  export default NomosFuelPrice 