import * as React from 'react';
import { ChangeEvent } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    useHits,
    useRefinementList,
    usePagination,
} from 'react-instantsearch-hooks-web';

import { SearchBox } from '../SearchBox'

import Pagination from '@material-ui/lab/Pagination';

import {
    // makeStyles,
    Paper,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
    Hidden,
    Divider,
    FormGroup,
} from "@material-ui/core";
import { ThemeUseStyles } from "../Theme"
const searchClient = algoliasearch('3DD3SXFSIX', '7773252636607f99394d07fba3baa49a');


function FilterSource() {
    const { items, refine } = useRefinementList({ attribute: 'Source' })
    // console.log("FilterSource",items)
    return (
        <>
            {items.map((item) => (
               <Typography variant="subtitle1" color="textPrimary" >
                    <FormControlLabel
                        control={<Checkbox checked={item.isRefined} value={item.value} onChange={() => refine(item.value)} name="checkedA" />}
                        label={item.value + " " + item.count}
                    // to do label={item.count}
                    />
                    </Typography>
            ))}
        </>
    )
}
function FilterKeyword() {
    const { items, refine } = useRefinementList({ attribute: 'KeyWod' })
    // console.log("FilterKeyWod",items)
    return (
        <>
        <FormGroup row>
            {items.map((item) => (
                <Typography variant="subtitle1" color="textSecondary" >
                        <FormControlLabel
                            control={<Checkbox checked={item.isRefined} value={item.value} onChange={() => refine(item.value)} name="checkedB" />}
                            label={item.value + " " + item.count}
                        />
                </Typography>
            ))}
            </FormGroup>
        </>
    )
}

function escape(htmlStr) {
    return htmlStr.replaceAll("&amp;", "&")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", '"')
        .replaceAll("&#39;", "'");
}

function Hits() {
    const { hits } = useHits();
    const classes = ThemeUseStyles();
    // console.log('hits', hits)
    const { nbPages, refine, isLastPage } = usePagination();
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        refine(value);
    };

    return (
        <Grid
            container
            justifyContent="center"
            spacing={3}
        >
            {hits.map((hit) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={hit._id}>
                    <Paper className={classes.bgplumplate}>
                        <Typography variant="h6" color="textPrimary">
                            {hit.objectID}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" >
                            {hit._highlightResult.Wod.map((item) => (
                                <div key={item.path}

                                    dangerouslySetInnerHTML={{
                                        __html:
                                            escape(item.value)
                                    }}>
                                </div>
                            )
                            )}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
            <Grid item >
                <Pagination
                    count={nbPages}
                    onChange={handlePageChange}
                    size='large'
                // sx={{ mt: 4 }}
                />
            </Grid>
        </Grid>
    );
}


function WodList() {
    const classes = ThemeUseStyles();
    return (
        <>
            <InstantSearch searchClient={searchClient} indexName="wod_northzone">
                <SearchBox placeholder="Search for wods" />
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Hidden xsDown>
                    <Grid item xs={12} >
                        <Grid container spacing={2}>
                        <Grid item xs={3} >
                            <Paper className={classes.bgplumplate}>
                                <FilterSource />
                            </Paper>
                        </Grid>
                        {/* <Divider /> */}
                        <Grid item xs={9}>
                            <Paper className={classes.bgplumplate}>
                                <FilterKeyword />
                            </Paper>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Hidden>
                    <Grid item xs={12}>
                        <Hits />
                    </Grid>
                </Grid>
            </InstantSearch>
        </>
    );
}
export default WodList