import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import {Colors_Legend, Colors_Legend_theme} from '../NivoTheme'
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    // Grid,
    Paper,
} from "@material-ui/core";


const data = [
    {
      "country": "AD",
      "tempmax": 87,
      "tempmaxColor": "hsl(163, 70%, 50%)",
      "tempmin": 180,
      "tempminColor": "hsl(234, 70%, 50%)",
      "humidity": 72,
      "humidityColor": "hsl(305, 70%, 50%)",
      "rain": 199,
      "rainColor": "hsl(26, 70%, 50%)",
    }
  ]

function NivoLegend() {
    const classes = ThemeUseStyles();


    return (
        <div className={classes.boxes}>
                        <>
                        <Paper className={classes.bgplumplate} elevation={3} >
                            <Typography variant="h6" gutterBottom>
                                Legend
                            </Typography>
                            <div style={{ height: 160 }}>
                            <ResponsiveBar
                            theme={Colors_Legend_theme}
                            // pointColor={{ theme: 'background' }}
                            // labelTextColor={Colors_Legend_theme}
                            // padding={0.6}
                            // borderRadius={6}
                            // labelSkipHeight={8}
                            layers={['legends']}
                            data={data}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 50,
                                    translateY: 0,
                                    itemsSpacing: 10,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 24,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            keys={[
                            "tempmax",
                            "tempmin",
                            "humidity",
                            "rain",
                            ]}
                            defs={[
                              // {
                              //   id: 'dots',
                              //   type: 'patternDots',
                              //   background: 'inherit',
                              //   color: '#3772A9',
                              //   size: 4,
                              //   padding: 2,
                              //   stagger: true
                              // },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#3C7CB9',
                                rotation: -50,
                                lineWidth: 1,
                                spacing: 5
                              }
                            ]}
                            fill={[
                              {
                                match: {
                                  id: 'humidity'
                                },
                                id: 'lines'
                              }
                            ]}
                            // indexBy="_id"
                            margin={{ top: 10, right: 0, bottom: 20, left: 0 }}
                            // minValue={-10}
                            // maxValue={60}
                            groupMode="grouped"
                            colors={Colors_Legend}
                            // colorBy="id"
                            // enableGridX={false}
                            // enableGridY={false}
                            // axisTop={null}
                            // axisRight={null}
                            // axisBottom={{
                            // orient: 'bottom',
                            // tickSize: 5,
                            // tickPadding: 5,
                            // tickRotation: 0,
                            // legend: 'temp',
                            // legendOffset: 36,
                            // legendPosition: 'center'
                            // }}
                            // axisLeft={null}
                            // animate={true}
                            // motionStiffness={90}
                            // motionDamping={15}
                            />
                             </div>
                        </Paper>
                    </>
             
        </div>
    )
}
export default NivoLegend;
