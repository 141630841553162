import React from "react";
import { ThemeUseStyles } from "../../Theme";
import {
    Typography,
    // CircularProgress,
    Box,
    Grid,
    Paper,
} from "@material-ui/core";

import OPWForecastDailyChartLine from '../../Nivo/OPWForecastChart/OPWForecastDailyChartLine'
import OPWForecastDailyChartBar from '../../Nivo/OPWForecastChart/OPWForecastDailyChartBar'

import '../../Utils/icons/OpeWeatherMap.css'
import '../../Utils/icons/weather-icons.min.css'
import '../../Utils/icons/weather-icons-wind.min.css'

import { FetchOPWLineChartDaily, FetchOPWforecastDailyHistory } from "../../../services/FirestoreAPI";

function OPWForecastDailyv2() {
    const classes = ThemeUseStyles();

    const OPWforecast = FetchOPWLineChartDaily();
    const OPWforecastDaily = FetchOPWforecastDailyHistory(7);

    return (
        <Paper className={classes.bgplumplate} elevation={3}>
            
                <Typography variant="h6" >
                    Daily
            </Typography>
            <Grid container className={classes.container} spacing={0}>
                {/* {isLoading ? (
                    <CircularProgress />
                ) : ( */}
                        <>
                            <Box key={OPWforecast.id} style={{ display: 'flex', width: '100%' }}>
                                <Box flexGrow={1}>
                                    <OPWForecastDailyChartLine data={OPWforecast} />
                                    <OPWForecastDailyChartBar data={OPWforecastDaily} />
                                </Box>
                            </Box>
                        </>
                    {/* )} */}
            </Grid>
        </Paper>
    )
}
export default OPWForecastDailyv2;

