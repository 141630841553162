export const Colors = ['#CCA43B', '#6F1D1B']

export const Colors_Bar_Label = ['white']

export const Colors_Legend = ['#6F1D1B', '#CCA43B', '#336699', '#336699' ]

export const Colors_Humidity = ['#336699', '#6F1D1B']

export const Colors_TempMinMax = ['#6F1D1B', '#336699' ]

export const Colors_Rain = ['#336699']

export const Colors_Icons = ['#336699']

export const GraphSettings = {
  theme: {
    fontSize: '12px',
    textColor: 'white',

  },
};


export const Colors_Legend_theme  = {
  // background: "#222222",
  axis: {
    fontSize: "14px",
    tickColor: "white",
    ticks: {
      line: {
        stroke: "white"
      },
      text: {
        fill: "white"
      }
    },
    legend: {
      text: {
        fill: "white"
      }
    }
  },
  grid: {
    line: {
      stroke: "white"
    }
  }
};

