import React from "react";
import { getIcon } from '../../Utils/WeatherUtils';
import WeatherIcon from '../../Utils/icons/WeatherIcon';
// import { makeStyles } from "@material-ui/core/styles";
import { Color_Text_Orange } from '../../Utils/Theme'
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    Grid,
    Paper,
} from "@material-ui/core";

import { FetchWuRain, FetchOPWforecastDaily, FetchOPWforecastHourly } from "../../../services/FirestoreAPI";


function timeconverter(unixtime) {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(unixtime * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime
}


function WuSkyv2() {
    const classes = ThemeUseStyles();
    const OPWforecastRain = FetchWuRain();
    const OPWforecastDaily = FetchOPWforecastDaily();
    const OPWforecastHourly = FetchOPWforecastHourly(1);

    return (
        <div className={classes.boxes}>
            {/* {isLoading ? (
                <CircularProgress />
            ) : ( */}
                    <>
                        <Paper className={classes.bgplumplate}>
                            <Typography variant="h6" gutterBottom>
                                Sky
                                </Typography>
                                {OPWforecastRain &&
                                <div key={"WuSky" + OPWforecastRain.id}>
                                    <Grid container>
                                        <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumn}
                                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }}gutterBottom>
                                                    {OPWforecastDaily && <div>
                                                        <i class="wi wi-sunrise"></i>
                                                        &nbsp;
                                                        {timeconverter(OPWforecastDaily.Sunrise)}
                                                        <br></br>
                                                    </div>
                                                    }
                                                    <Typography variant="h5" color="textSecondary" gutterBottom>sunrise</Typography>
                                                    </Typography>
                                                </Grid>
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >

                                                {/* <Typography>Center L.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                direction="column"
                                                align="left"
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                            >
                                                {/* <Typography>Bottom L.</Typography> */}
                                            </Grid>
                                        </Grid>
                                        {OPWforecastHourly.map(OPWforecastHourly => OPWforecastHourly &&
                                        <Grid container direction="column" item xs={4} align="center">
                                        
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                display="flex"
                                                justify="center"
                                            >
                                                {/* <Typography>Top Center</Typography> */}
                                            </Grid>
                                            
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                direction="column"
                                                display="flex"
                                                justify="center"
                                            >
                                                <Typography variant="h3" color="textPrimary" >
                                                
                                                <WeatherIcon name={getIcon(OPWforecastHourly.Icon)} />
                                                
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid
                                                item
                                                className={classes.centerColumn}
                                                container
                                                direction="row"
                                                alignItems="flex-end"
                                                justify="center"
                                            >
                                                <Typography variant="h5" style={{ color : Color_Text_Orange }}gutterBottom>
                                                {OPWforecastHourly.Description}
                                                    <br></br>
                                                    {/* <Typography variant="h8" color="textSecondary" gutterBottom >monthly</Typography> */}
                                                </Typography>
                                            </Grid>
                                            
                                        </Grid>
                                        )}
                                        <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    className={classes.outerColumn}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-end"
                                                    justify="flex-start"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }}gutterBottom>
                                                    {OPWforecastDaily && <div>
                                                        <i class="wi wi-sunrise"></i>
                                                        &nbsp;
                                                        {timeconverter(OPWforecastDaily.Sunset)}
                                                        <br></br>
                                                    </div>
                                                    }
                                                    <Typography display="block" variant="h5" align="right" color="textSecondary" >sunset</Typography>
                                                    </Typography>
                                                </Grid>
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Center R.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                direction="column"
                                                alignItems="flex-end"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Bottom R.</Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </Paper>
                    </>
                {/* )} */}

        </div>
    )
}
export default WuSkyv2;

