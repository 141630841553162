import { useState, useEffect } from "react";
import { db } from "../Firebase";
import { collection, onSnapshot, query, doc, limitToLast, orderBy, where } from "firebase/firestore";
import moment from 'moment';
// import { RoundedCorner } from "@material-ui/icons";

const today = new Date();
const currentdate = String(today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2))


function FetchHistoryRecords(id) {
    const [records, setRecords] = useState([]);
    // const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        const q = query(collection(db, "WuPerday"), orderBy("Date", "asc"), limitToLast(id));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                // console.log("Math", Math.round(doc.SolarRadiationHighMax))
                data.push({ id: moment(doc.id).format('DD-MM'), ...doc.data() });
            });
            setRecords(data);
            //   setLoading(false);
            // console.log("fire", data)
        });
    }, [id]);
    return records;
}


function FetchSpecificHistoryRecords(temp, symbol, num) {
    const [records, setRecords] = useState([]);
    // const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        const q = query(collection(db, "WuPerday"), where(temp, symbol, num));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            // data.sort((first, second) => first.id - second.id);
            // data.sort((item1, item2) => item1.Date - item2.Date);
            // data.sort((a, b) => parseFloat(b[id]) - parseFloat(a[id]));
            data.sort((a, b) => {
                return b.Date - a.Date;
            });
            let sortedData = data.sort((a, b) => moment(a.Date) - moment(b.Date))
            setRecords(sortedData);
            //   setLoading(false);
            
            // console.log("FetchSpecificHistoryRecords", data)
        });
    }, [temp, symbol, num]);
    return records;
}


function FetchOPWforecastHourly(id) {
    const [records, setRecords] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "OPWforecastHourly"), orderBy("DT", "desc"), limitToLast(id));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setRecords(data);
        });
    }, [id]);
    return records;
}

function FetchOPWforecastHourlyAsc(id) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "OPWforecastHourly"), orderBy("DT", "asc"), limitToLast(id));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setRecords(data);
        });
    }, [id]);
    return records;
}

function FetchWuETOLI1(id){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "WuETOLI1"), orderBy("Time", "asc"), limitToLast(id));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setRecords(data);
        });
    }, [id]);
    return records
}

function FetchWuRain(){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        onSnapshot(doc(db, "WuRain", "WuRain"), (doc) => {
            setRecords(doc.data());
        });
    }, []);
    return records
}

function FetchWuMoon(){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        onSnapshot(doc(db, "WuMoon", "WuMoon"), (doc) => {
            setRecords(doc.data());
        });
    }, []);
    return records
}

function FetchOPWforecastDaily(){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        onSnapshot(doc(db, "OPWforecastDaily", currentdate), (doc) => {
            setRecords(doc.data());
        });
    }, []);
    return records
}

function FetchOPWforecastDailyHistory(id){
    const [records, setRecords] = useState([]);
    useEffect(() => {
    const q = query(collection(db, "OPWforecastDaily"), orderBy("DT", "asc"), limitToLast(id));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: moment(doc.id).format('DD-MM'), ...doc.data() });
            });
            setRecords(data);
        });
    }, [id]);
    return records
}

function FetchOPWLineChartDaily(){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "OPWLineChartDaily"));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setRecords([].concat(data[1], data[0]));
        });
    }, []);
    return records
}

function FetchOPWLineChart(){
    const [records, setRecords] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "OPWLineChart"));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            // setRecords([].concat(data[1], data[0]));
            setRecords([].concat(data[0]));
        });
    }, []);
    return records
}



function FetchFuelPricesNomos(id) {
    const [records, setRecords] = useState([]);
    // const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        const q = query(collection(db, id), orderBy("Price", "asc"));
        return onSnapshot(q, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setRecords(data);
            //   setLoading(false);
            // console.log("FetchFuelPrices_Nomos", data)
        });
    }, [id]);
    return records;
}




export { FetchHistoryRecords, FetchSpecificHistoryRecords, FetchWuETOLI1, FetchOPWforecastDaily, FetchOPWforecastDailyHistory, FetchWuMoon, FetchWuRain, FetchOPWforecastHourly, FetchOPWLineChartDaily, FetchOPWLineChart, FetchOPWforecastHourlyAsc, FetchFuelPricesNomos }