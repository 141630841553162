import React from "react";
import { ThemeUseStyles } from "../../Theme";

import {
    Typography,
    // CircularProgress,
    Box,
    Grid,
    Paper,
} from "@material-ui/core";

import OPWForecastHourlyChartLine from '../../Nivo/OPWForecastChart/OPWForecastHourlyChartLine'
import OPWForecastHourlyChartBar from '../../Nivo/OPWForecastChart/OPWForecastHourlyChartBar'

import '../../Utils/icons/OpeWeatherMap.css'
import '../../Utils/icons/weather-icons.min.css'
import '../../Utils/icons/weather-icons-wind.min.css'

import { FetchOPWLineChart, FetchOPWforecastHourlyAsc } from "../../../services/FirestoreAPI";

function OPWForecastHourly() {
    const classes = ThemeUseStyles();

    const OPWforecast = FetchOPWLineChart();
    const OPWforecastHourly = FetchOPWforecastHourlyAsc(24);

    return (
        <Paper className={classes.bgplumplate} elevation={3} >
            <Typography variant="h6" >
                Hourly
            </Typography>

            <Grid container className={classes.container} spacing={0}>
                {/* {isLoading ? (
                    <CircularProgress />
                ) : ( */}
                        <>
                            <Box key={OPWforecast.id} style={{ display: 'flex', width: '100%' }}>
                                <Box flexGrow={1}>
                                    <OPWForecastHourlyChartLine data={OPWforecast} />
                                    <OPWForecastHourlyChartBar data={OPWforecastHourly} />
                                </Box>
                            </Box>
                        </>
                    {/* )} */}
            </Grid>
        </Paper>
    )
}
export default OPWForecastHourly;

