import React from "react";
import { Color_Text_Orange } from '../../Utils/Theme'
// import { MoonIcons } from '../../Utils/WeatherIcons';
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    Grid,
    Paper,
} from "@material-ui/core";

import { FetchWuRain } from "../../../services/FirestoreAPI";

function WuRainv2() {
    const classes = ThemeUseStyles();
    const OPWforecastRain = FetchWuRain();

    return (
        <div className={classes.boxes}>
            {/* {isLoading ? (
                <CircularProgress />
            ) : ( */}
                    <>
                        <Paper className={classes.bgplumplate}>
                            <Typography variant="h6" gutterBottom>
                                Rain
                                </Typography>
                                {OPWforecastRain &&
                                <div key={"WuRain" + OPWforecastRain.id}>
                                    <Grid container>
                                        <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumn}
                                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        {OPWforecastRain.Yesterday}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom>yesterday</Typography>
                                                    </Typography>
                                                </Grid>
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >

                                                {/* <Typography>Center L.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                direction="column"
                                                align="left"
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                            >
                                                {/* <Typography>Bottom L.</Typography> */}
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column" item xs={4} align="center">
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                display="flex"
                                                justify="center"
                                            >
                                                {/* <Typography>Top Center</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                direction="column"
                                                display="flex"
                                                justify="center"
                                            >
                                                <Typography variant="h3" color="textPrimary" >
                                                {OPWforecastRain.Today}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.centerColumn}
                                                container
                                                direction="row"
                                                alignItems="flex-end"
                                                justify="center"
                                            >
                                                <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                    {OPWforecastRain.Monthly}
                                                    <br></br>
                                                    <Typography variant="h5" color="textSecondary" gutterBottom >monthly</Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column" item xs={4}>
                                                <Grid
                                                    item
                                                    className={classes.outerColumn}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-end"
                                                    justify="flex-start"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        {OPWforecastRain.Weekly}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom >weekly</Typography>
                                                    </Typography>
                                                </Grid>
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Center R.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                direction="column"
                                                alignItems="flex-end"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Bottom R.</Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </Paper>
                    </>
                {/* )} */}

        </div>
    )
}
export default WuRainv2;

