import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Colors_Bar_Label, Colors_Humidity, GraphSettings } from '../../Nivo/NivoTheme'
import { ThemeUseStyles } from '../../Theme';
// import moment from "moment";

function AllHistoryHeatIndexChartBar (data) {
    // const data = props.data
    const classes = ThemeUseStyles();
    const getData = () => {
      let resultArray = Object.keys(data).map(i => data[i])
      return resultArray.reverse()
    }
    // console.log("historykey", data)

    return (
      <>
        <div style={{ display: 'flex', height: '20%' }}>
          <ResponsiveBar
            theme={GraphSettings.theme}
            labelTextColor={Colors_Bar_Label}
            isInteractive={false}
            borderRadius={6}
            labelSkipHeight={8}
            layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', 'points']}
            data={getData()[0]}
            keys={['HeatindexHigh']}
            indexBy="id"
            margin={{ top: 20, bottom: 20, left: 0, right: 0 }}
            minValue={0}
            padding={0.5}
            groupMode="grouped"
            colors={Colors_Humidity}
            colorBy="Date"
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 2,
              tickPadding: 4,
              tickRotation: 8,
            }}
            axisLeft={null}
            animate={false}
          />
        </div>
      </>
    );
  }


export default AllHistoryHeatIndexChartBar 

