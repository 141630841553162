import React from "react";
import WeatherIcon from '../../Utils/icons/WeatherIcon';
import { WindSpeedBar } from '../../Utils/WeatherIcons'
import { Color_Text_Orange } from '../../Utils/Theme'
import { ThemeUseStyles } from "../../Theme"

import {
    Typography,
    // CircularProgress,
    Grid,
    Paper,
} from "@material-ui/core";

import { FetchWuETOLI1, FetchOPWforecastDaily } from "../../../services/FirestoreAPI";

function WuWindv2() {
    const classes = ThemeUseStyles();
    const OPWforecast = FetchWuETOLI1(1);
    const OPWforecastDaily = FetchOPWforecastDaily();
    
    return (
        <div className={classes.boxes}>
            {/* {isLoading ? (
                <CircularProgress />
            ) : ( */}
                    <>
                        <Paper className={classes.bgplumplate}>
                            <Typography variant="h6" gutterBottom>
                                Wind
                                </Typography>

                            {OPWforecast.map(OPWforecast => OPWforecast &&
                                <div key={"WuWind" + OPWforecast.id}>
                                    <Grid container>
                                        <Grid container direction="column" item xs={4}>
                                            {OPWforecastDaily &&
                                                <Grid
                                                    item
                                                    xs
                                                    className={classes.outerColumn}
                                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        {OPWforecast.WindName}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom>direction</Typography>
                                                    </Typography>
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >

                                                {/* <Typography>Center L.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                className={classes.outerColumn}
                                                // direction="column"
                                                align="left"
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                            >
                                                {/* <Typography>Bottom L.</Typography> */}
                                            </Grid>
                                        </Grid>
                                        <Grid container  item xs={4} align="center">
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                display="flex"
                                                justify="center"
                                            >
                                                {/* <Typography>Top Center</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                className={classes.centerColumn}
                                                // direction="column"
                                                display="flex"
                                                justify="center"
                                            >
                                                <Typography variant="h3" color="textPrimary" >
                                                    <WeatherIcon name={WindSpeedBar(OPWforecast.WindSpeed)} />
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.centerColumn}
                                                container
                                                direction="row"
                                                alignItems="flex-end"
                                                justify="center"
                                            >
                                                <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                    {OPWforecast.WindGust}
                                                    <br></br>
                                                    <Typography variant="h5" color="textSecondary" gutterBottom >gust</Typography>
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column" item xs={4}>
                                            {OPWforecastDaily &&
                                                <Grid
                                                    item
                                                    className={classes.outerColumn}
                                                    container
                                                    // direction="column"
                                                    alignItems="flex-end"
                                                    justify="flex-start"
                                                >
                                                    <Typography variant="h5" style={{ color : Color_Text_Orange }} gutterBottom>
                                                        {OPWforecast.WindGRName}
                                                        <br></br>
                                                        <Typography variant="h5" color="textSecondary" gutterBottom >anemologio</Typography>
                                                    </Typography>
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Center R.</Typography> */}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.outerColumn}
                                                container
                                                // direction="column"
                                                alignItems="flex-end"
                                                justify="flex-end"
                                            >
                                                {/* <Typography>Bottom R.</Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}


                        </Paper>
                    </>
                {/* )} */}

        </div>
    )
}
export default WuWindv2;

