import React, { memo } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

export default memo(function Footer() {
  return (
    <>
      <Typography variant="body2" align="center">
        ietoli1 weather station data
        <strong> </strong>
      </Typography>
      <br />
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="/">
          zzzremos.com
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </>
  );
});
