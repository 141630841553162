// import React from "react";
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { colors}  from "@material-ui/core";

export const Color_Text_Orange = ['#eb8404']

export const THEME = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3c91e8",
        dark: '#151b20'
      },
      secondary: {
        main: colors.red[500],
        dark: '#151b20'
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h6: {
        fontSize: 15,
        // fontFamily: "Montserrat",
        fontWeight: 300,
        color: "#3c91e8",
        letterSpacing: "0.0075em",
        verticalAlign: "middle",
        // alignItems: "center",
        // textAlign: "center"
      },
      h5: {
        fontSize: '0.5rem',
        '@media (min-width:600px)': {
          fontSize: '0.5rem',
        },
        '@media (min-width:1200px)': {
          fontSize: '0.7rem',
        },
      },
      subtitle1: {
        fontSize: '0.8rem',
        '@media (min-width:600px)': {
          fontSize: '0.9rem',
        },
        '@media (min-width:1200px)': {
          fontSize: '0.9rem',
        },
      },
    },
    text: {
      primary: 'white',
      secondary: 'black',
    }
  });
  
  export const ThemeUseStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      backgroundColor: '#0b1015',
      minHeight: '100%',
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(8),
    },
    avatar: {
      margin: 10,
    },
    boxes: {
    flexGrow: 1,
    },
    bgplumplate: {
        width: '100%',
        backgroundColor: theme.palette.secondary.dark
        // backgroundColor: '#f5f5f5',
    },
    centerColumn: {
        padding: 5,
        height: 53,
    },
    outerColumn: {
        padding: 5,
        height: 53.2,
  },
  outerColumnlarge: {
    padding: 5,
    height: 80,
},
}));


  export default THEME;