export const icons = {
  '01d': 'wi-day-sunny',
  '02d': 'wi-day-cloudy',
  '03d': 'wi-day-cloudy',
  '04d': 'wi-cloudy',
  '09d': 'wi-day-showers',
  '10d': 'wi-day-rain',
  '11d': 'wi-day-thunderstorm',
  '13d': 'wi-day-snow-wind',
  '50d': 'wi-fog',
  '01n': 'wi-night-clear',
  '02n': 'wi-night-partly-cloudy',
  '03n': 'wi-night-alt-cloudy',
  '04n': 'wi-cloudy',
  '09n': 'wi-night-alt-showers',
  '10n': 'wi-night-alt-rain',
  '11n': 'wi-night-thunderstorm',
  '13n': 'wi-night-alt-snow-wind',
  '50n': 'wi-fog',
};

export const wuicons = {
  '0': 'wi-tornado',
  '2': 'wi-hurricane',
  '3': 'wi-thunderstorm',
  '4': 'wi-thunderstorm',
  '5': 'wi-rain-mix',
  '6': 'wi-rain-mix',
  '7': 'wi-rain-mix',
  '8': 'wi-rain-wind',
  '9': 'wi-rain-mix',
  '10': 'wi-rain-mix',
  '12': 'wi-rain-mix',
  '13': 'wi-snow',
  '14': 'wi-snow',
  '15': 'wi-snow-wind',
  '16': 'wi-snow',
  '17': 'wi-hail',
  '18': 'wi-sleet',
  '19': 'wi-dust',
  '20': 'wi-dust',
  '21': 'wi-dust',
  '22': 'wi-dust',
  '23': 'wi-windy',
  '24': 'wi-windy',
  '25': 'wi-snowflake-cold',
  '26': 'wi-cloudy',
  '27': 'wi-night-cloudy',
  '28': 'wi-day-cloudy',
  '29': 'wi-night-cloudy',
  '30': 'wi-day-cloudy',
  '31': 'wi-day-sunny',
  '32': 'wi-day-sunny',
  '33': 'wi-night-cloudy',
  '34': 'wi-day-cloudy',
  '35': 'wi-day-cloudy',
  '36': 'wi-hot',
  '37': 'wi-day-thunderstorm',
  '38': 'wi-day-thunderstorm',
  '39': 'wi-day-showers',
  '40': 'wi-rain',
  '41': 'wi-day-showers',
  '42': 'wi-snow',
  '43': 'wi-snow',
  '45': 'wi-night-alt-showers',
  '46': 'wi-night-alt-snow',
  '47': 'wi-night-alt-thunderstorm', 
};



export const WindDirection = (w) => {
  if (w < 23) {
    return 'wicon wi wi-wind towards-0-deg';
  } else if (w < 45) {
    return 'wicon wi wi-wind towards-23-deg';
  } else if (w < 68){
    return 'wicon wi wi-wind towards-45-deg';
  } else if (w < 90){
    return 'wicon wi wi-wind towards-68-deg';
  } else if (w < 113){
    return 'wicon wi wi-wind towards-90-deg';
  } else if (w < 135){
    return 'wicon wi wi-wind towards-113-deg';
  } else if (w < 158){
    return 'wicon wi wi-wind towards-135-deg';
  } else if (w < 180){
    return 'wicon wi wi-wind towards-158-deg';
  } else if (w < 203){
    return 'wicon wi wi-wind towards-180-deg';
  } else if (w < 225){
    return 'wicon wi wi-wind towards-203-deg';
  } else if (w < 248){
    return 'wicon wi wi-wind towards-225-deg';
  } else if (w < 270){
    return 'wicon wi wi-wind towards-248-deg';
  } else if (w < 293){
    return 'wicon wi wi-wind towards-270-deg';
  } else if (w < 313){
    return 'wicon wi wi-wind towards-293-deg';
  } else if (w < 336){
    return 'wicon wi wi-wind towards-313-deg';
  } else {
    return 'wi wi-wind towards-336-deg';
  }
}


export const WindDirectionBar = (w) => {
  if (w < 23) {
    return 'wicon wi wi-wind towards-0-deg';
  } else if (w < 45) {
    return 'wicon wi wi-wind towards-23-deg';
  } else if (w < 68){
    return 'wicon wi wi-wind towards-45-deg';
  } else if (w < 90){
    return 'wicon wi wi-wind towards-68-deg';
  } else if (w < 113){
    return 'wicon wi wi-wind towards-90-deg';
  } else if (w < 135){
    return 'wicon wi wi-wind towards-113-deg';
  } else if (w < 158){
    return 'wicon wi wi-wind towards-135-deg';
  } else if (w < 180){
    return 'wicon wi wi-wind towards-158-deg';
  } else if (w < 203){
    return 'wicon wi wi-wind towards-180-deg';
  } else if (w < 225){
    return 'wicon wi wi-wind towards-203-deg';
  } else if (w < 248){
    return 'wicon wi wi-wind towards-225-deg';
  } else if (w < 270){
    return 'wicon wi wi-wind towards-248-deg';
  } else if (w < 293){
    return 'wicon wi wi-wind towards-270-deg';
  } else if (w < 313){
    return 'wicon wi wi-wind towards-293-deg';
  } else if (w < 336){
    return 'wicon wi wi-wind towards-313-deg';
  } else {
    return 'wi wi-wind towards-336-deg';
  }
}

export const WindSpeed = (w) => {
  if (w < 1) {
    return 'wi wi-wind-beaufort-0';
  } else if (w < 2) {
    return 'wi wi-wind-beaufort-1';
  } else if (w < 3) {
    return 'wi wi-wind-beaufort-2';
  } else if (w < 4) {
    return 'wi wi-wind-beaufort-3';
  } else if (w < 5) {
    return 'wi wi-wind-beaufort-4';
  } else if (w < 6) {
    return 'wi wi-wind-beaufort-5';
  } else if (w < 7) {
    return 'wi wi-wind-beaufort-6';
  } else if (w < 8) {
    return 'wi wi-wind-beaufort-7';
  } else if (w < 9) {
    return 'wi wi-wind-beaufort-8';
  } else if (w < 10) {
    return 'wi wi-wind-beaufort-9';
  } else if (w < 11) {
    return 'wi wi-wind-beaufort-10';
  } else if (w < 12) {
    return 'wi wi-wind-beaufort-11';
  } else if (w < 13) {
    return 'wi wi-wind-beaufort-12';
  } else {
    return 'wi wi-wind-beaufort-0';
  }
}


export const WindSpeedBar = (w) => {
  if (w < 1) {
    return 'wi-wind-beaufort-0';
  } else if (w < 2) {
    return 'wi-wind-beaufort-1';
  } else if (w < 3) {
    return 'wi-wind-beaufort-2';
  } else if (w < 4) {
    return 'wi-wind-beaufort-3';
  } else if (w < 5) {
    return 'wi-wind-beaufort-4';
  } else if (w < 6) {
    return 'wi-wind-beaufort-5';
  } else if (w < 7) {
    return 'wi-wind-beaufort-6';
  } else if (w < 8) {
    return 'wi-wind-beaufort-7';
  } else if (w < 9) {
    return 'wi-wind-beaufort-8';
  } else if (w < 10) {
    return 'wi-wind-beaufort-9';
  } else if (w < 11) {
    return 'wi-wind-beaufort-10';
  } else if (w < 12) {
    return 'wi-wind-beaufort-11';
  } else if (w < 13) {
    return 'wi-wind-beaufort-12';
  } else {
    return 'wi-wind-beaufort-0';
  }
}


export const MoonIcons = (w) => {
  if (w < 1) {
    return 'wi wi-moon-new';
} else if (w === 1) {
  return 'wi wi-moon-waxing-crescent-1';
} else if (w === 2) {
    return 'wi wi-moon-waxing-crescent-1';
} else if (w === 3) {
    return 'wi wi-moon-waxing-crescent-2';
} else if (w === 4) {
    return 'wi wi-moon-waxing-crescent-3';
} else if (w === 5) {
    return 'wi wi-moon-waxing-crescent-4';
} else if (w === 6) {
    return 'wi wi-moon-waxing-crescent-5';
} else if (w === 7) {
    return 'wi wi-moon-waxing-crescent-6';
} else if (w === 8) {
    return 'wi wi-moon-first-quarter';
} else if (w === 9) {
    return 'wi wi-moon-waxing-gibbous-1';
} else if (w === 10) {
    return 'wi wi-moon-waxing-gibbous-2';
} else if (w === 11) {
    return 'wi wi-moon-waxing-gibbous-3';
} else if (w === 12) {
    return 'wi wi-moon-waxing-gibbous-4';
} else if (w === 13) {
    return 'wi wi-moon-waxing-gibbous-5';
} else if (w === 14) {
    return 'wi wi-moon-waxing-gibbous-6';
} else if (w === 15) {
    return 'wi wi-moon-full';
} else if (w === 16) {
    return 'wi wi-moon-full';
} else if (w === 17) {
    return 'wi wi-moon-waning-gibbous-1';
} else if (w === 18) {
    return 'wi wi-moon-waning-gibbous-2';
} else if (w === 19) {
    return 'wi wi-moon-waning-gibbous-3';
} else if (w === 20) {
    return 'wi wi-moon-waning-gibbous-4';
} else if (w === 21) {
    return 'wi wi-moon-waning-gibbous-5';
} else if (w === 22) {
    return 'wi wi-moon-waning-gibbous-6';
} else if (w === 23) {
    return 'wi wi-moon-third-quarter';
} else if (w === 24) {
    return 'wi wi-moon-waning-crescent-1';
} else if (w === 25) {
    return 'wi wi-moon-waning-crescent-2';
} else if (w === 26) {
    return 'wi wi-moon-waning-crescent-3';
} else if (w === 27) {
    return 'wi wi-moon-waning-crescent-4';
} else if (w === 28) {
    return 'wi wi-moon-waning-crescent-5';
} else if (w === 29) {
    return 'wi wi-moon-waning-crescent-6';
} else if (w === 30) {
    return 'wi wi-moon-new';
}
}
