import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import {Colors, GraphSettings} from '../../NivoTheme'

import { FetchWuMoon } from "../../../../services/FirestoreAPI";

function lower(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] === 'string') {
            obj[prop] = obj[prop].toLowerCase();
        }
        if (typeof obj[prop] === 'object') {
            lower(obj[prop]);
        }
    }
    return obj;
}

const toLowerCase = function (data) {
    return JSON.parse(JSON.stringify(data)
        .replace(/"([^"]+)":/g, ($0, key) => {
            return '"' + key.toString().toLowerCase() + '":'
        }))
}

function timeconverterToHour(unixtime) {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(unixtime * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Will display time in 10:30:23 format
    const formattedTime = hours
    return formattedTime
}

function timeconverter(unixtime) {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(unixtime * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime
}


// export class OPWForecastHourlyChartLine extends React.PureComponent {

function OPWForecastHourlyChartLine(data) {
    
    // render() {
        
        const OPWforecastRain = FetchWuMoon();
        // const data = id
        const jsontolowercase = toLowerCase(lower(data))
        const result = jsontolowercase.data
        console.log("hourly", result)

        // console.log("sun", timeconverter(OPWforecastRain.SunsetTimeUtc))
        const markers = [
            {
                axis: 'x',
                value: timeconverterToHour(OPWforecastRain.SunsetTimeUtc),
                legend: 'sunset ' + timeconverter(OPWforecastRain.SunsetTimeUtc),
                lineStyle: {
                    stroke: '#D3C2CE',
                    strokeWidth: 3,
                    strokeOpacity: 0.35,
                    strokeDasharray: '3 2',
                    // transform: 'translateX(8px)'
                },
                // lineStyle: {stroke: '#B4B4B4', strokeWidth: 1, strokeDasharray: 2},
                textStyle: { fontFamily: 'sans-serif', fontSize: '10px', fill: '#D3C2CE' },
                // legendOrientation: 'vertical',
                legendPosition: 'top',
                


            },
            {
                axis: 'x',
                value: timeconverterToHour(OPWforecastRain.SunriseTimeUtc),
                legend: 'sunrise ' + timeconverter(OPWforecastRain.SunriseTimeUtc),
                lineStyle: {
                    stroke: '#D3C2CE',
                    strokeWidth: 3,
                    strokeOpacity: 0.35,
                    strokeDasharray: '3 2',
                    // transform: 'translateX(12px)'
                },
                textStyle: { fontFamily: 'sans-serif', fontSize: '10px', fill: '#D3C2CE' },
                // legendOrientation: 'vertical',
                legendPosition: 'top'
            },
        ]

        return (
            <div style={{ height: '52%', userSelect: 'none' }}>
                <ResponsiveLine
                    theme={GraphSettings.theme}
                    layers={['grid', 'axes', 'lines', 'markers', 'legends', 'annotations', 'points']}
                    data={result}
                    margin={{ top: 40, right: 40, bottom: -10, left: 40 }}
                    // xScale={{ type: 'point' }}
                    // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    // yFormat=" >-.0f"
                    axisTop={null}
                    axisRight={null}
                    curve="catmullRom"
                    colors={Colors}
                    markers={markers}
                    // enableArea={true}
                    // areaOpacity={0.8}
                    // axisBottom={{
                    //     orient: 'bottom',
                    //     tickSize: 5,
                    //     tickPadding: 2,
                    //     tickRotation: 0,
                    //     // legend: 'temp',
                    //     legendOffset: 36,
                    //     legendPosition: 'middle'
                    // }}
                    axisLeft={null}
                    axisBottom={null}
                    enableGridX={false}
                    enableGridY={false}
                    lineWidth={3}
                    // pointColor={{ theme: 'background' }}
                    pointBorderWidth={1}
                    pointSize={4}
                    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                    enablePointLabel={true}
                    pointLabelYOffset={-4}
                    // enableArea={true}
                    useMesh={true}
                    // legends={[]}
                />
            </div>
        );
    }


export default OPWForecastHourlyChartLine



